<template>
  <div class="day">
    <h1>
      <span class="day-date">{{ day.dateTxt }}</span><br>
      <span class="day-name">{{ day.name }}</span>
    </h1>
    <div class="day-type" v-for="t in day.types" :key="'type-' + t.id">
      <h2>{{ t.name }}</h2>
      <template v-for="i in day.services">
        <router-link class="day-link" v-if="i.type === t.id"
                     :to="{name:'service', params: { date: day.date, id: i.id }}" :key="'day-' + i.id">
          {{ i.name }}
        </router-link>
      </template>
    </div>
    <!--    <a class="day-lit" href="http://radio.zakonbozhiy.ru:8000/live.mp3" target="_blank">Онлайн-трансляция богослужения</a>-->
    <a v-if="day.skype" class="day-lit" :href="day.skype" target="_blank">Подключение к трансляции урока</a>
    <div class="day-back">
      <router-link :to="{name: 'home'}">К списку материалов</router-link>
    </div>
  </div>
</template>

<script>
  import {mapState} from 'vuex';

  export default {
    name: "Day",
    created() {
      document.title = "Помощник чтеца";
      this.fetch()
    },
    watch: {
      $route: 'fetch'
    },
    computed: {
      ...mapState({
        day: state => state.currDay
      })
    },
    methods: {
      fetch() {
        this.$store.dispatch('selectDay', this.$route.params.date);
      }
    },
  }
</script>

<style scoped lang="less">
  @import "../assets/vars";

  .day {
    h1 {
      margin: 0 0 1rem 0;
      padding: 0;
      text-align: center;
      font-size: 1.2rem;
      color: @color-h;

      .day-date {
        font-size: 1.5rem;
      }
    }

    .day-type {
      margin-bottom: 2rem;

      h2 {
        margin: 0 0 .5rem 0;
        padding: 0;
        font-size: 1.2rem;
        color: @color-d;
        text-align: center;
      }

      .day-link {
        display: block;
        padding: .5rem;
        text-decoration: none;
        margin-bottom: 1rem;
        background-color: @color-h;
        color: @color-l;
        text-align: center;

        &:last-child {
          margin-bottom: 0;
        }

      }
    }

    .day-lit {
      display: block;
      padding: .5rem;
      text-decoration: none;
      margin: 3rem 0 1rem 0;
      background-color: @color-h;
      color: @color-l;
      text-align: center;
    }

    .day-back {
      text-align: center;

      a {
        color: @color-h;
        text-decoration: none;
      }
    }
  }

</style>
